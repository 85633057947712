import { useSaleProductListApi } from '~/common/api/product'
import { useShop } from '~/common/api/shop'

import { useProductsWithCoupon } from '~/common/app/product'
import { Color } from '~/common/utils/color'
import ProductScrollList from '~/components/app/module/product/ProductScrollList'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import Content from '~/components/layouts/area/Content'
import TitleList from '~/components/layouts/title/TitleList'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import Loading from '~/components/atoms/loading/Loading'

const ProductSaleList = () => {
  const shop = useShop()
  const { data: productList, isLoading } = useSaleProductListApi()
  const products = useProductsWithCoupon(productList)

  return (
    <Content scroll>
      <TitleList>
        <BlockTitle icon={IconType.TIMER} iconColor={Color.SUPPORT_SUB}>
          期間限定セール
        </BlockTitle>
      </TitleList>
      {products && products.length > 0 &&
        <ProductScrollList
          type={ProductCardType.MEDIUM}
          products={products}
        />
      }
      {!isLoading && products?.length == 0 &&
        <>
          {shop ?
              <>{shop.baseInfo.name}にセール中の商品はありません。</> :
              <>セール中の商品は商品はありません。</>
          }
        </>
      }
      {isLoading &&
        <Loading />
      }
    </Content>
  )
}

export default ProductSaleList

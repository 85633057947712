import { addClass } from '~/common/utils/html'
import { Color, textColorClass } from '~/common/utils/color'

interface Props {
  color?: Color
  point?: number
}

const MarkedText = ({ color = Color.SECONDARY, point = 1 }: Props) => {
  const className = 'c-text-marked' + addClass(textColorClass(color))
  return <span className={className}>{point}</span>
}

export default MarkedText

import { useNewsList } from '~/common/api/news'
import { newsPath } from '~/common/app/path'
import NewsRow, { NewsRowType } from '~/components/atoms/news/NewsRow'
import NewsList from '~/components/atoms/news/NewsList'
import Content from '~/components/layouts/area/Content'

const AppNewsList = () => {
  const newses = useNewsList()
  return (
    <Content low>
      <NewsList>
        {(newses)?.map((news) =>
          <NewsRow key={news.id} text={news.title} link={news.linkable ? newsPath(news) : undefined}
                   color={news.shop_theme_color ? '#' + news.shop_theme_color : undefined}
                   type={!!news.shop_theme_color ? NewsRowType.NORMAL : NewsRowType.ALERT} />
        )}
      </NewsList>
    </Content>
  )
}

export default AppNewsList

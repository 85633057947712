import React from 'react'

import { ShopDetail } from '~/model/shop'
import {
  ShopPickupProductsListResponse,
  usePickupProductListApi
} from '~/common/api/product'

import { productListPath } from '~/common/app/path'
import { useProductsWithCoupon } from '~/common/app/product'
import ProductScrollList from '~/components/app/module/product/ProductScrollList'
import TitleList from '~/components/layouts/title/TitleList'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import Content from '~/components/layouts/area/Content'

const ProductPickupList = () => {

  const pickupProductListPath = (shop: ShopDetail) => {
    return productListPath({
      key: shop?.baseInfo.shop_key ?? undefined,
      pickup_product: true
    })
  }

  const { data: shopPickupProductsList } = usePickupProductListApi()

  const products = shopPickupProductsList?.flatMap((shopPickup) => shopPickup.products)
  const productsWithCoupon = useProductsWithCoupon(products)

  const shopPickupProductsWithCouponList = shopPickupProductsList?.map((shopPickup) => {
    const productsWithCouponForShop = productsWithCoupon?.filter((product) => shopPickup.shop.baseInfo.shop_key == product.shop.shop_key)

    return {
      ...shopPickup,
      products: productsWithCouponForShop,
    }
  })

  const title = (shopPickupProducts: ShopPickupProductsListResponse) =>
    shopPickupProducts.shop.baseInfo.name + 'のピックアップ'

  return (
    <>
      {(shopPickupProductsWithCouponList)?.map((shopPickupProducts, index) =>
        shopPickupProducts.products &&
        <Content scroll key={index}>
          <TitleList>
            <BlockTitle>
              {title(shopPickupProducts)}
            </BlockTitle>
          </TitleList>
          <ProductScrollList type={ProductCardType.SMALL} products={shopPickupProducts.products}
                             shop={shopPickupProducts.shop}
                             link={pickupProductListPath(shopPickupProducts.shop)} />
        </Content>
      )}
    </>

  )
}

export default ProductPickupList

import InternalLink from '~/components/utils/link/InternalLink'
import ExternalLink from '~/components/utils/link/ExternalLink'
import ImageFitContain from '~/components/utils/image/ImageFitContain'

interface Props {
  images: {
    src: string
    alt: string
    link?: string
    external?: boolean
  }[]
}

interface ItemProps {
  index: number
}

const ImageGrid = ({ images }: Props) => {
  const ImageGridItem = ({ index }: ItemProps) => {
    return (
      <li className='l-image-grid__item'>
        {!images[index].link ?
          <ImageFitContain src={images[index].src} alt={images[index].alt} /> :
          <>
            {images[index].external ? (
              <ExternalLink link={images[index].link as string}>
                <ImageFitContain src={images[index].src} alt={images[index].alt} />
              </ExternalLink>
            ) : (
              <InternalLink link={images[index].link as string}>
                <ImageFitContain src={images[index].src} alt={images[index].alt} />
              </InternalLink>
            )}
          </>
        }
      </li>
    )
  }
  return (
    <>
      {images && (
        <div className='l-image-grid'>
          <ul className='l-image-grid__list'>
            {images[0] && <ImageGridItem index={0} />}
            {images[1] && <ImageGridItem index={1} />}
          </ul>
          <ul className='l-image-grid__list'>
            {images[2] && <ImageGridItem index={2} />}
            {images[3] && <ImageGridItem index={3} />}
          </ul>
        </div>
      )}
    </>
  )
}
export default ImageGrid

import React from 'react'

import { useShop } from '~/common/api/shop'
import { useAppLargeProductCardPropsList, useProductList, useProductsWithCoupon } from '~/common/app/product'
import Content from '~/components/layouts/area/Content'
import CardList from '~/components/molecules/card/CardList'
import PrimaryTitle from '~/components/atoms/title/PrimaryTitle'
import AppProductCard from '~/components/app/module/product/AppProductCard'

const ProductAllList = () => {
  const shop = useShop()
  const SORT_CATEGORY_ID = 5
  const { products: productList } = useProductList(SORT_CATEGORY_ID)
  const products = useProductsWithCoupon(productList)

  const cardPropsList = useAppLargeProductCardPropsList(products)

  return (
    <>
      <Content>
        <PrimaryTitle>{shop?.baseInfo.name}のすべての商品</PrimaryTitle>
        <CardList noScroll>
          {cardPropsList?.map((cardProps) => (
            <AppProductCard key={cardProps.product.id} {...cardProps} />
          ))}
        </CardList>
      </Content>
    </>
  )
}

export default ProductAllList

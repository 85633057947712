import { MouseEvent } from 'react'
import { addClass } from '~/common/utils/html'
import Icon, { IconType } from '~/components/atoms/icon/Icon'
import InternalLink from '~/components/utils/link/InternalLink'

export const NewsRowType = {
  ALERT: 'ALERT',
  NORMAL: 'NORMAL'
} as const

export type NewsRowType = (typeof NewsRowType)[keyof typeof NewsRowType]

const newsRowTypeClass = (type: NewsRowType): string => {
  switch (type) {
    case NewsRowType.ALERT:
      return 'c-news-row--alert'
    case NewsRowType.NORMAL:
      return 'c-news-row--normal'
  }
}

const News = ({ color, text }: { color: string, text: string }) => {
  return (
    <>
      <Icon color={color} size={12} type={IconType.ALERT} />
      <span className='c-news-row__text' dangerouslySetInnerHTML={{ __html: text }} />
    </>
  )
}

interface Props {
  text: string
  link?: string
  type?: NewsRowType
  color?: string

  onClick?(e: MouseEvent<HTMLElement>): void
}

const NewsRow = ({ text, link, type = NewsRowType.ALERT, color = '#cc0214' }: Props) => {
  const className = 'c-news-row' + addClass(' c-news-row--link', !!link) + addClass(newsRowTypeClass(type))

  return (
    <li>
      {link ?
        <InternalLink link={link} className={className}>
          <News color={color} text={text} />
        </InternalLink> :
        <span className={className}>
          <News color={color} text={text} />
        </span>
      }
    </li>
  )
}

export default NewsRow

import { useTokubaiProductListApi } from '~/common/api/product'
import { useShop } from '~/common/api/shop'

import { productListPath } from '~/common/app/path'
import { useProductsWithCoupon } from '~/common/app/product'
import { Color } from '~/common/utils/color'
import ProductScrollList from '~/components/app/module/product/ProductScrollList'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import LinkText from '~/components/atoms/text/LinkText'
import Content from '~/components/layouts/area/Content'
import TitleList from '~/components/layouts/title/TitleList'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'

const ProductTokubaiList = () => {
  const {data: productData} = useTokubaiProductListApi()
  const shop = useShop()
  const saleProductListPath = productListPath({ key: shop?.baseInfo.shop_key, sale_product: true})
  const products = useProductsWithCoupon(productData?.products)

  return (
    <>
      {productData?.products && (
        <Content scroll>
          <TitleList>
            <BlockTitle icon={IconType.BAG} iconColor={Color.SUPPORT_SUB}>
              特売商品一覧
            </BlockTitle>
            <LinkText arrow={Color.LINK} link={saleProductListPath} px={12}>
              すべて見る
            </LinkText>
          </TitleList>
          <ProductScrollList
            type={ProductCardType.MEDIUM}
            products={products || []}
            link={saleProductListPath}
          />
        </Content>
      )}
    </>
  )
}

export default ProductTokubaiList

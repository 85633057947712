import { MouseEvent } from 'react'

import Slick from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import ImageFitContain from '~/components/utils/image/ImageFitContain'
import ExternalLink from '~/components/utils/link/ExternalLink'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props {
  slideData: {
    id: number
    src: string
    alt: string
    link?: string
    external: boolean
  }[]
}

interface ArrowProps {
  onClick?(e: MouseEvent<HTMLElement>): void
}

const Slider = ({ slideData }: Props) => {
  const NextArrow = ({ onClick }: ArrowProps) => {
    return (
      <button className='u-slider-item__arrow u-slider-item__arrow--next' onClick={onClick}>
        <ImageFitContain src='/assets/images/slick-arrow-right.png' alt='' />
      </button>
    )
  }

  const PrevArrow = ({ onClick }: ArrowProps) => {
    return (
      <button className='u-slider-item__arrow u-slider-item__arrow--prev' onClick={onClick}>
        <ImageFitContain src='/assets/images/slick-arrow-left.png' alt='' />
      </button>
    )
  }

  const SLIDER_MIN = 2
  const shopSliderCount = slideData.length < SLIDER_MIN ? slideData.length : SLIDER_MIN

  const settings = {
    autoplay: true,
    waitForAnimate: false,
    autoplaySpeed: 4000,
    arrows: true,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '8.1%',
    focusOnSelect: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          initialSlide: 1,
          slidesToShow: shopSliderCount,
          slidesToScroll: shopSliderCount
        }
      }
    ]
  }
  return (
    <div className='u-slider-item-wrap'>
      <ul className='u-slider-item'>
        <Slick {...settings}>
          {slideData.map((slide) => {
            return (
              <li key={slide.id}>
                <div className='u-slider-item__item'>
                  {slide.link ? (
                    slide.external ? (
                      <ExternalLink link={slide.link}>
                        <img src={slide.src} alt={slide.alt} width='319' height='213' />
                      </ExternalLink>
                    ) : (
                      <InternalLink link={slide.link}>
                        <img src={slide.src} alt={slide.alt} width='319' height='213' />
                      </InternalLink>
                    )
                  ) : (
                    <span>
                      <img src={slide.src} alt={slide.alt} width='319' height='213' />
                    </span>
                  )}
                </div>
              </li>
            )
          })}
        </Slick>
      </ul>
    </div>
  )
}

export default Slider

import { ShopDetail } from '~/model/shop'

import { useCarousel } from '~/common/api/carousel'
import { isInternalLink } from '~/common/utils/url'
import { savedImagePath } from '~/common/app/path'
import { useShop } from '~/common/api/shop'
import { lineToBreak } from '~/common/utils/string'
import { STYLE_MEDIA_PC, STYLE_MEDIA_SP } from '~/common/app/style'
import ImageGrid from '~/components/layouts/image/ImageGrid'
import Slider from '~/components/utils/slider/Slider'
import AppPointStatus from '~/components/app/module/user/AppPointStatus'
import ImageFitContain from '~/components/utils/image/ImageFitContain'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import TopCouponArea from '~/components/app/module/coupon/TopCouponArea'

const ShopInfo = ({ shop }: { shop: ShopDetail }) => {
  return (
    <div>
      <span>
        <ImageFitContain src={savedImagePath(shop.shop_image_file)} />
      </span>
      <p>{lineToBreak(shop.message)}</p>
      <style jsx>{`
        div {
          font-size: 12px;
          padding: 12px;
          border-radius: 4px;
          background-color: ${'#' + shop.theme_color + '3D'};
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          flex: 1;
        }

        span {
          width: 240px;
          aspect-ratio: 420/280;
        }

        p {
          overflow: hidden;
          flex: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 10;
        }

        @media ${STYLE_MEDIA_PC} {
          div {
            padding: 16px;
            flex-direction: row-reverse;
            height: 200px;
            max-width: 700px;
          }
        }
      `}</style>
    </div>
  )
}

const TopBannerCarousel = () => {
  const data = useCarousel()
  const shop = useShop()

  const sliderImages = data ? data.mainBanners.map((banner, index) => {
    return {
      id: banner.id,
      src: savedImagePath(banner.banner_image_file),
      link: banner.url,
      alt: 'TOPスライドバナー画像' + (index + 1),
      external: !isInternalLink(banner.banner_image_file)
    }
  }) : []

  const gridImages = data ? data.subBanners.map((banner, index) => {
    return {
      src: savedImagePath(banner.banner_image_file),
      link: banner.url,
      alt: 'TOPバナー画像' + (index + 1),
      external: !isInternalLink(banner.banner_image_file)
    }
  }) : []

  // Not use common components for slick flex bug and carousel case breakpoint
  return (
    <div className={'l-carousel'}>
      {sliderImages.length > 0 ?
        <Slider slideData={sliderImages} /> :
        <>
          {shop &&
            <ShopInfo shop={shop} />
          }
        </>
      }
      {gridImages.length > 0 &&
        <ImageGrid images={gridImages} />
      }
      <div className={'l-privilege-area'}>
        <Segments
          spColumn={SegmentsType.COLUMN}
          pcColumn={SegmentsType.COLUMN}
          spGap={12}
          pcGap={16}
        >
          <TopCouponArea />
          <AppPointStatus />
        </Segments>
      </div>
      <style jsx>{`
        @media screen and (max-width: 1280px) {
          .l-privilege-area {
            margin-top: 16px;
          }
        }

        @media screen and (min-width: 1280px) {
          .l-carousel {
            display: flex;
            gap: 24px;
            align-items: center;
            justify-content: center;
          }
        }

        @media ${STYLE_MEDIA_SP} {
          .l-carousel {
            padding: 0 12px;
          }
        }
      `}</style>
    </div>
  )
}

export default TopBannerCarousel

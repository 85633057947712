import { ChildProps } from '~/common/utils/props'
import OpacityToggle from '~/components/utils/animation/OpacityToggle'

interface Props extends ChildProps {
  show: boolean
}

const ScreenFloat = ({ children, show }: Props) => {
  return (
    <OpacityToggle className='l-float-screen' show={show}>
      {children}
    </OpacityToggle>
  )
}

export default ScreenFloat

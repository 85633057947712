import { useShop } from '~/common/api/shop'
import { useCategoryList } from '~/common/api/category'
import { Color } from '~/common/utils/color'
import AppNewsList from '~/components/app/module/news/AppNewsList'
import ShopHeader from '~/components/app/module/shop/ShopHeader'
import TopBannerCarousel from '~/components/app/module/banner/TopBannerCarousel'
import ShopLinkList from '~/components/app/module/shop/ShopLinkList'
import ShopList from '~/components/app/module/shop/ShopList'
import CategoryList from '~/components/app/module/category/CategoryList'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import ProductFavoriteList from '~/components/app/module/product/ProductFavoriteList'
import Content from '~/components/layouts/area/Content'
import HiddenPc from '~/components/utils/hidden/HiddenPc'
import MarginTop from '~/components/utils/margin/MarginTop'
import ProductPickupList from '~/components/app/module/product/ProductPickupList'
import ProductRankingList from '~/components/app/module/product/ProductRankingList'
import ProductPurchasedList from '~/components/app/module/product/ProductPurchasedList'
import ProductTokubaiList from '~/components/app/module/product/ProductTokubaiList'
import ProductSaleList from '~/components/app/module/product/ProductSaleList'
import ProductCollectionList from '~/components/app/module/product/ProductCollectionList'
import SelectedShopFloat from '~/components/app/module/shop/SelectedShopFloat'
import ProductRecentBrowsedList from '~/components/app/module/product/ProductRecentBrowsedList'
import ProductAllList from '~/components/app/module/product/ProductAllList'
import CategorySlider from '~/components/app/module/category/CategorySlider'

const ShopTitleList = () => {
  return (
    <Content scroll>
      <HiddenPc>
        <BlockTitle icon={IconType.SHOP} iconColor={Color.SUPPORT_SUB}>
          お店から探す
        </BlockTitle>
        <ShopList />
      </HiddenPc>
    </Content>
  )
}

const TopPage = () => {
  const shop = useShop()
  const categories = useCategoryList()

  return (
    <>
      {shop &&
        <SelectedShopFloat shop={shop} />
      }
      {shop ?
        <>
          <ShopLinkList />
          <ShopHeader shop={shop} />
          <MarginTop marginSp={16}>
            <CategorySlider categories={categories} />
          </MarginTop>
        </> :
        <>
          <ShopLinkList />
          <CategorySlider categories={categories} />
        </>
      }
      <AppNewsList />
      <TopBannerCarousel />
      {!shop &&
          <ShopTitleList />
      }
      {shop?.scale === 'small' &&
          <ProductAllList />
      }
      {(!shop || shop?.scale === 'large') &&
        <CategoryList />
      }
      <ProductTokubaiList />
      <ProductFavoriteList />
      <ProductPurchasedList />
      <ProductSaleList />
      <ProductCollectionList />
      {(!shop || shop?.scale === 'large') &&
        <ProductPickupList />
      }
      <ProductRecentBrowsedList />
      <ProductRankingList />
      {shop &&
        <ShopTitleList />
      }
    </>
  )
}

export default TopPage

import { useRankingProductList } from '~/common/api/product'

import { useProductsWithCoupon } from '~/common/app/product'
import { Color } from '~/common/utils/color'
import ProductScrollList from '~/components/app/module/product/ProductScrollList'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import Content from '~/components/layouts/area/Content'

const ProductRankingList = () => {
  const productList = useRankingProductList()
  const products = useProductsWithCoupon(productList)

  return (
    <>
      {products && products.length > 0 &&
        <Content scroll>
          <BlockTitle icon={IconType.RANKING} iconColor={Color.SUPPORT_SUB}>
            売れ筋商品
          </BlockTitle>
          <ProductScrollList type={ProductCardType.SMALL} products={products} ranking />
        </Content>
      }
    </>
  )
}

export default ProductRankingList

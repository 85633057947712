import React, { useEffect, useState } from 'react'

import { useCouponListApi, useShowCouponModal } from '~/common/api/coupon'
import { Color } from '~/common/utils/color'
import { myPageCouponPath } from '~/common/app/path'
import { calculateAddPointRate, extractCouponUsageLimitItems } from '~/common/app/coupon'
import { isDate } from '~/common/utils/string'
import Text from '~/components/atoms/text/Text'
import CardList from '~/components/molecules/card/CardList'
import MediumCouponCard from '~/components/molecules/card/MediumCouponCard'
import SimpleArea from '~/components/layouts/area/SimpleArea'
import Loading from '~/components/atoms/loading/Loading'
import LinkText from '~/components/atoms/text/LinkText'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import { useIsSpDevice } from '~/components/utils/window'

const TopCouponArea = () => {
  const { data: couponsData, isLoading } = useCouponListApi()
  const coupons = couponsData?.coupons
  const hasOtherShop = couponsData?.has_other_shop_coupon
  const isSp = useIsSpDevice()

  // PCの場合は1枚表示
  const adjustCountCoupons = isSp ? coupons || [] : coupons ? coupons.slice(0, 1) : []

  const showCouponModal = useShowCouponModal()

  return (
    <Segments spGap={4} spColumn={SegmentsType.COLUMN} pcColumn={SegmentsType.COLUMN} fitWidth>
      <Segments pcBetween spBetween pcAlignEnd spAlignEnd fitWidth>
        <BlockTitle icon={IconType.COUPON} iconColor={Color.SUPPORT_SUB}>保有クーポン</BlockTitle>
        <LinkText px={12} link={myPageCouponPath()} arrow={Color.LINK}>
          すべて見る
        </LinkText>
      </Segments>

        {isLoading ? (
          <Loading />
        ) : (
          (adjustCountCoupons && adjustCountCoupons.length > 0) ? (
            <CardList noScroll={false}>
              {adjustCountCoupons?.map((coupon) =>
                <MediumCouponCard
                  key={coupon.id}
                  name={coupon.name}
                  addPointRate={calculateAddPointRate(coupon)}
                  discountPrice={coupon.discount_price}
                  usageLimitItems={extractCouponUsageLimitItems(coupon, false)}
                  expireDate={isDate(coupon.available_to) ? `${coupon.available_to}まで` : coupon.available_to}
                  themeColor={'#' + coupon.theme_color}
                  onClick={() => showCouponModal(coupon)}
                />
              )}
            </CardList>
          ) : (
              hasOtherShop ? (
                <SimpleArea>
                  <Text tag='p' px={10}>
                    この店舗で利用できるクーポンがありません。
                  </Text>
                  <LinkText px={10} link={myPageCouponPath()}>
                    他の店舗で利用可能なクーポンを見る
                  </LinkText>
                </SimpleArea>
              ) : (
                <SimpleArea>
                  <Text tag='p' px={10}>
                    保有しているクーポンがありません
                  </Text>
                </SimpleArea>
              )
          )
        )}
    </Segments>
  )
}

export default TopCouponArea

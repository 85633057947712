import useSWR from 'swr'

import { ApiPath, fetchApi } from '~/common/api/base'
import Banner from '~/model/banner'
import { usePathKey } from '~/common/app/path'
import { definedFilter } from '~/common/utils/array'

export interface CarouselResponse {
  mainBanners: Banner[]
  subBanners: Banner[]
}

export const fetchCarousel = async (key?: string) => {
  const { data } = await fetchApi<CarouselResponse>(ApiPath.CAROUSEL, { key })
  return data
}

export const useCarousel = () => {
  const key = usePathKey()
  const { data } = useSWR<CarouselResponse>([ApiPath.CAROUSEL, definedFilter({
    key
  })])
  return data
}

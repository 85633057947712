import { useState, useEffect } from 'react'
import { ShopDetail } from '~/model/shop'
import { SHOP_HEADER_TAG_ID } from '~/common/app/shop'
import { Color } from '~/common/utils/color'
import { useRedirectHome } from '~/common/api/user'
import ScreenFloat from '~/components/layouts/float/ScreenFloat'
import Tag, { TagType } from '~/components/atoms/tag/Tag'
import LinkText from '~/components/atoms/text/LinkText'
import Text from '~/components/atoms/text/Text'

interface Props {
  shop: ShopDetail
}

const SelectedShopFloat = ({ shop }: Props) => {
  const [isShow, setIsShow] = useState(false)
  const toggleVisibility = () => {
    const shopHeaderPositionBottom = document.getElementById(SHOP_HEADER_TAG_ID)?.getBoundingClientRect()[
      'bottom'
      ]
    if (shopHeaderPositionBottom) {
      shopHeaderPositionBottom < 0 ? setIsShow(true) : setIsShow(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  const redirectHome = useRedirectHome()
  return (
    <ScreenFloat show={isShow}>
      <Tag tagType={TagType.FLOAT}>
        <Text omit color={Color.INVERSION}>
          {`「${shop.baseInfo.name}」`}
        </Text>
        <span>
          を閲覧中。
        </span>
        <LinkText px={12} onClick={() => redirectHome()} color={Color.INVERSION}
                  underline>選択解除</LinkText>
        <style jsx>{`
          span {
            margin-right: 16px;
          }
        `}</style>
      </Tag>
    </ScreenFloat>
  )
}

export default SelectedShopFloat

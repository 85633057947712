import {
  CollectionProductsListResponse,
  useCollectionProductsList
} from '~/common/api/product'

import { productListPath, usePathKey } from '~/common/app/path'
import { useProductsWithCoupon } from '~/common/app/product'
import { Color } from '~/common/utils/color'
import ProductScrollList from '~/components/app/module/product/ProductScrollList'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import LinkText from '~/components/atoms/text/LinkText'
import Content from '~/components/layouts/area/Content'
import TitleList from '~/components/layouts/title/TitleList'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'

const ProductCollectionList = () => {
  const key = usePathKey()
  const collectionProductListPath = (shopCollectionProducts: CollectionProductsListResponse) =>
    productListPath({
      key: shopCollectionProducts.shop_key.length > 0 ? shopCollectionProducts.shop_key : key ?? undefined,
      collection_code: shopCollectionProducts.collection_code
    })

  const shopCollectionProductsList = useCollectionProductsList()
  const products = shopCollectionProductsList?.flatMap((collection) => collection.products)
  const productsWithCoupon = useProductsWithCoupon(products)

  const shopCollectionProductsWithCouponList = shopCollectionProductsList?.map((collection, index) => {

    const productsWithCouponForCollection = collection.products.flatMap((collectionProduct) => {
      return productsWithCoupon.find((productWithCoupon) => collectionProduct.id === productWithCoupon.id)
    })

    return {
      ...collection,
      products: productsWithCouponForCollection,
    } as CollectionProductsListResponse
  })

  return (
    <>
      {shopCollectionProductsWithCouponList?.map((shopCollectionProducts, index) =>
        shopCollectionProducts.products &&
        <Content scroll key={index}>
          <TitleList>
            <BlockTitle icon={IconType.BAG} iconColor={Color.SUPPORT_SUB}>
              {shopCollectionProducts.collection_name}
            </BlockTitle>
            <LinkText arrow={Color.LINK} link={collectionProductListPath(shopCollectionProducts)}
                      px={12}>
              すべて見る
            </LinkText>
          </TitleList>
          <ProductScrollList
            type={ProductCardType.MEDIUM}
            products={shopCollectionProducts.products}
            link={collectionProductListPath(shopCollectionProducts)}
          />
        </Content>
      )}
    </>
  )
}

export default ProductCollectionList

import { useShowModal } from '~/common/app/store/modal'
import { useCustomerCpApi, useLoggedIn } from '~/common/api/user'
import { Color } from '~/common/utils/color'
import { myPageLoginPath } from '~/common/app/path'
import PointCampaign from '~/components/molecules/detail/PointCampaign'
import Button, { ButtonRole, ButtonSize } from '~/components/atoms/button/Button'
import { Modal } from '~/components/app/modal/AppModal'
import PointTimesItem from '~/components/molecules/detail/PointTimesItem'

const AppPointStatus = () => {
  const loggedIn = useLoggedIn()
  const show = useShowModal()
  const { data } = useCustomerCpApi(loggedIn)

  return (
    <PointCampaign
      description={'今週利用すれば来週(月〜日)はポイント2倍!!'}
      link={myPageLoginPath()}
      color={Color.BEIGE_MAIN}
      descriptionBlack
    >
      {loggedIn ?
        <>
          <PointTimesItem
            title={'今週のお買い物'}
            strong={data?.is_buy_last_week}
            point={data?.is_buy_last_week ? 2 : 1}
          />
          <PointTimesItem
            title={'来週のお買い物'}
            strong={data?.is_buy_this_week}
            point={data?.is_buy_this_week ? 2 : 1}
          />
        </> :
        <Button
          size={ButtonSize.LARGE}
          role={ButtonRole.HIGH_PRIORITY_YELLOW}
          onClick={() => show({ type: Modal.LOGIN })}
          flex
        >
          ログインして達成状況を確認
        </Button>
      }
    </PointCampaign>
  )
}

export default AppPointStatus

import { ReactNode } from 'react'
import { ChildProps } from '~/common/utils/props'

interface Props extends ChildProps {
  subText?: string | ReactNode
}

const PrimaryTitle = ({ children, subText }: Props) => {
  return (
    <div className='p-primary-title'>
      <h2 className='c-primary-title'>
        <span className='c-primary-title__text'>{children}</span>
      </h2>
      {subText && <span className='c-primary-title__subtext'>{subText}</span>}
    </div>
  )
}

export default PrimaryTitle

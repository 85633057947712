import React from 'react'

import Category from '~/model/category'

import { productListPath, usePathKey } from '~/common/app/path'
import SlideSelectorItem, { SelectorItemType } from '~/components/atoms/selector/SlideSelectorItem'
import SlideSelectorList from '~/components/atoms/selector/SlideSelectorList'

interface Props {
  categories?: Category[]
  currentCategoryId?: number
}

const CategorySlider = ({ categories, currentCategoryId }: Props) => {
  const key = usePathKey()
  return (
    <>
      {categories != undefined && categories?.length > 0 &&
        <SlideSelectorList bottomSpace>
          {categories?.map((category) => (
            <SlideSelectorItem
              key={category.id}
              text={category.name}
              link={productListPath({ key, category_id: category.id })}
              type={SelectorItemType.POPULAR}
              current={currentCategoryId === category.id}
            />
          ))}
        </SlideSelectorList>
      }
    </>
  )
}

export default CategorySlider

import useSWR from 'swr'

import { ApiPath, fetchApi } from '~/common/api/base'
import News from '~/model/news'
import { usePathKey } from '~/common/app/path'
import { definedFilter } from '~/common/utils/array'

export const fetchNewsList = async (key?: string) => {
  const { data } = await fetchApi<News[]>(ApiPath.NEWS_LIST, { key })
  return data
}

export const useNewsList = () => {
  const key = usePathKey()
  const { data } = useSWR<News[]>([ApiPath.NEWS_LIST, definedFilter({
    key
  })])
  return data
}

import { Color } from '~/common/utils/color'
import { addClass } from '~/common/utils/html'
import MarkedText from '~/components/atoms/text/MarkedText'
import Text from '~/components/atoms/text/Text'

interface Props {
  title: string
  strong?: boolean
  point?: number
}

const PointTimesItem = ({ title, strong = false, point = 1 }: Props) => {
  const pointColor = point >= 2 ? Color.ASSERTION : Color.SECONDARY
  const className = 'p-point-times' + addClass('p-point-times--strong', strong)
  return (
    <div className='p-point-cp-item'>
      <div className='p-point-cp-item__content'>
        <Text px={10} bold>
          {title}
        </Text>
      </div>
      <div className={className}>
        <Text px={10}>ポイント</Text>
        <MarkedText point={point} color={pointColor} />
        <Text px={10}>倍</Text>
      </div>
    </div>
  )
}
export default PointTimesItem
